import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { images } from '../../config';
import './Dashboard.css';
import { getDistributionCount, getTransactionSummary, getCustomerActivity, getBanner, getCard } from './service';
import { setUserLogin } from "../Login/LoginSlice/LoginSlice";

const Dashboard = () => {

    const [distributionCount, setDistributionCount] = useState([]);
    const [transactionSummary, setTransactionSummary] = useState([]);
    const [customerActivity, setcustomerActivity] = useState([]);
    const [bannerImage, setBannerImage] = useState(null);
    const [cardImage, setCardImage] = useState([])
    const [open, setOpen] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    console.log("distributionCount", distributionCount);
    console.log("transactionSummary", transactionSummary);
    console.log("customerActivity", customerActivity);

    const dispatch = useDispatch()


    useEffect(() => {
        fetchDistributionData();
        fetchTransactionSummary();
        fetchCustomerActivity();
        fetchBanner();
        fetchCard();
    }, []);

    const fetchDistributionData = async () => {
        try {
            const response = await getDistributionCount()
            if (response.status === 200 || response.status === 201) {
                setDistributionCount(response.data.payload);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchTransactionSummary = async () => {
        try {
            const response = await getTransactionSummary()
            if (response.status === 200 || response.status === 201) {
                setTransactionSummary(response.data.payload)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const fetchCustomerActivity = async () => {
        try {
            const response = await getCustomerActivity()
            if (response.status === 200 || response.status === 201) {
                setcustomerActivity(response.data.counts);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const fetchBanner = async () => {
        try {
            const response = await getBanner();
            console.log("API Response Data", response.data);
            if (response.status === 200 || response.status === 201) {
                setBannerImage(response.data.payload);
                console.log("responnsebottomBanner", response.data.payload)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        return null;
    };

    const fetchCard = async () => {
        try {
            const response = await getCard();
            if (response.status === 200 || response.status === 201) {
                setCardImage(response.data.payload);
                console.log('responseCardPOPUP', response.data.payload)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const handleClose = () => {
        dispatch(setUserLogin({ isDistributorWelcomImageShown: true }));
        setOpen(false);
    };

    useEffect(() => {
        const handleSidebarToggle = () => {
            const storedValue = localStorage.getItem('sidebarOpen') === 'true';
            console.log('Sidebar toggled, new value:', storedValue);
            setSidebarOpen(storedValue);
        };

        // Initial load
        handleSidebarToggle();

        // Listen for the custom event
        window.addEventListener('sidebarToggle', handleSidebarToggle);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('sidebarToggle', handleSidebarToggle);
        };
    }, []);

    const { userDetail, isDistributorWelcomImageShown } = useSelector((state) => state.userLogin);

    useEffect(() => {
        // Automatically open dialog if conditions are met
        if (userDetail.user_role === 'Distributor' && !isDistributorWelcomImageShown) {
            setOpen(true);

        }
    }, [userDetail, isDistributorWelcomImageShown]);


    const baseURL = "https://admin.isecureapps.com";
    const firstImage = bannerImage && bannerImage.length > 0 && bannerImage[bannerImage.length - 1].image ? bannerImage[bannerImage.length - 1].image : null;
    const firstCardImage = cardImage.length > 0 ? cardImage[cardImage.length - 1].card_image : null;
    console.log("firstCardImagePOPUP", firstCardImage);
    console.log("dashboard123", userDetail);

    return (
        <>
            {userDetail.user_role === 'Distributor' && firstCardImage && (
                <Card className='dashboard-card'>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        className='dashboard-card'
                    >
                        <img
                            src={firstCardImage}
                            alt="Card Image"
                            style={{ width: '100%', height: 'auto' }}
                        />
                        <DialogActions>
                            <Button onClick={handleClose} autoFocus>
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Card>
            )}

            <Container maxWidth="xxl" className={`pt-4 pb-0 pt-sm-4 pb-sm-0 pt-md-4 pb-mb-0 pt-lg-4 pb-lg-0 pt-xl-3 pb-xl-3 ${sidebarOpen ? 'sidebar-open-dashboard' : 'dashboard-container'}`}>
                {/* <Container maxWidth="xxl" className={`pt-4 pb-0 pt-sm-4 pb-sm-0 pt-md-4 pb-mb-0 pt-lg-4 pb-lg-0 pt-xl-5 pb-xl-3 ${sidebarOpen ? 'sidebar-open-text' : 'sidebar-close-text'}`}> */}
                <div className='dashboard-container-wrap'>
                    <div className='dashboard-right'>
                        <div className='dashboard user-text'>
                            <h2 className='dashboard user-name'>Welcome, {userDetail.username}</h2>
                            <h6>All systems are running smoothly....!</h6>
                        </div>
                        {/* First Grid */}
                        <div>
                            <h5 className='transaction-summary'>Transaction Summary</h5>
                        </div>
                        <div className='transaction-box-wrap'>
                            {/* 1 */}
                            <div className='box-wrap'>
                                <div className='box'>
                                    <div className='transaction-text-box'>
                                        <div className='transaction-box-inner-wrap'>
                                            <div>
                                                <h6>
                                                    Debit Points
                                                </h6>
                                            </div>
                                            <div>
                                                <img
                                                    src={images.debitIcon}
                                                    alt="image"
                                                    className='transaction-point-icon'
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <h5>
                                                {/* 37 */}
                                                {transactionSummary.debit_points}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 2 */}
                            <div className='box-wrap'>
                                <div className='box'>
                                    <div className='transaction-text-box'>
                                        <div className='transaction-box-inner-wrap'>
                                            <div>
                                                <h6>
                                                    Credit Points
                                                </h6>
                                            </div>
                                            <div>
                                                <img
                                                    src={images.creditIcon}
                                                    alt="image"
                                                    className='transaction-point-icon'
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <h5>
                                                {transactionSummary.credit_points}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 3 */}
                            <div className='box-wrap'>
                                <div className='box'>
                                    <div className='transaction-text-box'>
                                        <div className='transaction-box-inner-wrap'>
                                            <div>
                                                <h6>
                                                    Balance Points
                                                </h6>
                                            </div>
                                            <div>
                                                <img
                                                    src={images.balanceIcon}
                                                    alt="image"
                                                    className='transaction-point-icon'
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <h5>
                                                {transactionSummary.balance_points}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Second Grid */}
                        <div>
                            <h5 className='transaction-summary'>Customer Activity</h5>
                        </div>
                        <div className='customer-activity-wrap'>
                            {/* 1 */}
                            <div className='customer-activity-box'>
                                <div className='customer-activity-details'>
                                    <div>
                                        <img
                                            src={images.inActiveIcon}
                                            alt="image"
                                            className='activity-icon'
                                        />
                                    </div>
                                    <div>
                                        <h5>Active </h5>
                                    </div>
                                    <div>
                                        <h6>
                                            {customerActivity.Active}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            {/* 2 */}
                            <div className='customer-activity-box'>
                                <div className='customer-activity-details'>
                                    <div>
                                        <img
                                            src={images.installIcon}
                                            alt="image"
                                            className='activity-icon'
                                        />
                                    </div>
                                    <div>
                                        <h5>Install</h5>
                                    </div>
                                    <div>
                                        <h6>
                                            {customerActivity.Installed}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            {/* 3 */}
                            <div className='customer-activity-box'>
                                <div className='customer-activity-details'>
                                    <div>
                                        <img
                                            src={images.deActiveIcon}
                                            alt="image"
                                            className='activity-icon'
                                        />
                                    </div>
                                    <div>
                                        <h5>Deactivate</h5>
                                    </div>
                                    <div>
                                        <h6>
                                            {customerActivity.Deactivated}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            {/* 4 */}
                            <div className='customer-activity-box'>
                                <div className='customer-activity-details'>
                                    <div>
                                        <img
                                            src={images.unInstallIcon}
                                            alt="image"
                                            className='activity-icon'
                                        />
                                    </div>
                                    <div>
                                        <h5>Uninstall</h5>
                                    </div>
                                    <div>
                                        <h6>
                                            {customerActivity.Uninstalled}
                                        </h6>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* Third Grid */}
                        <div className='customer-activity-box-wrap'>
                            {/* 1 */}
                            {userDetail.user_role !== 'Distributor' && (
                                <div className={userDetail.user_role !== 'Distributor' ? 'customer-details' : 'customer-details1'}>
                                    <div className='customer-detail-box'>
                                        <div>
                                            <img
                                                src={images.distributionIcon}
                                                alt="image"
                                                className='activity-icon'
                                            />
                                        </div>
                                        <div>
                                            <h5>Distribution</h5>
                                        </div>
                                        <div>
                                            <h6>
                                                {/* 2 */}
                                                {distributionCount.distributors}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* 2 */}
                            <div className='customer-details'>
                                <div className='customer-detail-box'>
                                    <div>
                                        <img
                                            src={images.customerIcon}
                                            alt="image"
                                            className='activity-icon'
                                        />
                                    </div>
                                    <div>
                                        <h5>Customers</h5>
                                    </div>
                                    <div>
                                        <h6>
                                            {distributionCount.customers}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            {/* 3 */}
                            <div className='customer-details'>
                                <div className='customer-detail-box'>
                                    <div>
                                        <img
                                            src={images.retailerIcon1}
                                            alt="image"
                                            className='activity-icon'
                                        />
                                    </div>
                                    <div>
                                        <h5>Retailer</h5>
                                    </div>
                                    <div>
                                        <h6>
                                            {distributionCount.retailers}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='dashboard-left'>
                        <div className='image-wrapper'>
                            <div className='image-container'>
                                <img
                                    src={images.scanner}
                                    alt="image"
                                    className='scanner-image'
                                />
                            </div>
                            <div className='image-container-1'>
                                {userDetail.user_role === 'Superadmin' ? (
                                    <img
                                        src={images.dashboardImg}
                                        alt="image"
                                        className='dashboard-image'
                                    />
                                ) : userDetail.user_role === 'Distributor' && firstImage ? (
                                    <img 
                                        src={firstImage} 
                                        alt="Banner Image" 
                                        style={{ width: '100%', height: 'auto' }} 
                                    />
                                ) : (
                                    <img
                                        src={images.dashboardImg}
                                        alt="Fallback Image"
                                        className='dashboard-image'
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>

    );
};

export default Dashboard;