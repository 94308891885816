import React, { useEffect, useState } from "react";
import AuthenticationRoutes from "./AuthenticationRoutes";
import UnAuthenticationRoutes from "./UnAuthenticationRoutes";
import Sidebar from "../pages/Sidebar/Sidebar";
// import { BrowserRouter as Router,Route, Routes,Navigate  } from "react-router-dom";
import store from "../redux/store/store";
import { useSelector } from 'react-redux';
import {
    useLocation,
} from "react-router-dom";

const App = () => {

    const { userDetail } = useSelector((state) => state.userLogin);
    const location = useLocation();


    console.log("Current route:1", location.pathname);

 

    // if (userDetail && userDetails === null) {
    //     return <div>LOADING...........................</div>
    // }
    return (
        <>
            {/* <Router> */}
            {/* {user?.userDetail?.token ? (<><Router><Sidebar><AuthenticationRoutes /></Sidebar></Router></>) : <UnAuthenticationRoutes />} */}
            {console.log("userDetail", userDetail && userDetail.email && userDetail.token)}
            {userDetail  && userDetail.token ? (<><Sidebar><AuthenticationRoutes /></Sidebar></>) : <UnAuthenticationRoutes />}
            {/* </Router> */}

        </>
    );
}

export default App;
