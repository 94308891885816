import axios from "axios";

export const url = {
  apiBaseUrl: "https://isecure.srashtasoft.in",
  // apiBaseUrl: "https://admin.isecureapps.com",
};

export const axiosInstance = axios.create({
  baseURL: "https://isecure.srashtasoft.in/",
  // baseURL: "https://admin.isecureapps.com",
});
